html.no-js{
  .wf-load{
    opacity: 1;
  }
  .top-bar {
    display: block;
  }
  .nav--main {
    display: block;
  }

}
